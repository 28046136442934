<template>
  <vx-card>
    <template>
      <!-- BEGIN: ข้อมูลเพิ่มเติมการคืนชุด POP UP -->
      <vs-popup
        classContent="popup-example"
        title="ข้อมูลเพิ่มเติมในการคืนชุด"
        :active.sync="popupActive"
      >
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->
        <!--------------------------------------------------------------------->

        <!-- BEGIN: RETURN DETAIL -->

        <div class="grid-layout-container alignment-block label-font" style=" width:100%;">

          <!--  ธนาคาร  -->
          <vs-row style="font-weight:bold; margin-top:20px; " class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >วิธีการคืนเงิน</vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="7"
            vs-sm="7"
            vs-xs="7"
            class="form-label px-2"
          >
            <div
              :class="[returnPaymentMethod === 'เงินสด' ? 'choose' : 'notChoose']"
              @click="changeReturnPayment('เงินสด')"
            >เงินสด</div>
            <div
              :class="[returnPaymentMethod === 'โอนเงิน' ? 'choose' : 'notChoose']"
              @click="changeReturnPayment('โอนเงิน')"
            >โอนเงิน</div>
          </vs-col>
          </vs-row>

          <!--  โน้ต/หมายเหตุ  -->
          <vs-row style="font-weight:bold; margin-top:20px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >โน้ต/หมายเหตุ</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <vs-textarea
                class="w-full"
                name="remark"
                v-model="remark"
                style="border: 1px #cccccc solid; "
              />
            </vs-col>
          </vs-row>
          <!--  หักเงินประกัน  -->
          <vs-row style="font-weight:bold; margin-top:20px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >หักเงินประกัน</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <div
                class="grid-layout-container alignment-block"
                style="vertical-align:text-top ;width:100%;"
              >
                <vs-row vs-w="12">
                  <CurrencyValue class="w-full" v-model="deduction"></CurrencyValue>
                </vs-row>
                <vs-row vs-w="12">
                  <div class="little-comment the-blue">
                    (วงเงินประกัน
                    {{ formatPrice(bailTotal - bailDiscount) }} บาท)
                  </div>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>

          <!--  ธนาคาร  -->
          <vs-row v-if="returnPaymentMethod==='โอนเงิน'" style="font-weight:bold; margin-top:20px; " class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >ธนาคาร</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <vs-input class="w-full" name="bankName" v-model="bankName" />
            </vs-col>
          </vs-row>

          <!--  ชื่อบัญชีลูกค้า  -->
          <vs-row v-if="returnPaymentMethod==='โอนเงิน'" style="font-weight:bold; margin-top:20px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >ชื่อบัญชีลูกค้า</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <vs-input class="w-full" name="accountName" v-model="accountName" />
            </vs-col>
          </vs-row>

          <!--  เลขที่บัญชีลูกค้า  -->
          <vs-row v-if="returnPaymentMethod==='โอนเงิน'" style="font-weight:bold; margin-top:20px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >เลขที่บัญชีลูกค้า</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <vs-input class="w-full" name="accountNumber" v-model="accountNumber" />
            </vs-col>
          </vs-row>

          <!--  จ่ายคืนจริง  -->
          <vs-row v-if="returnPaymentMethod!=='โอนเงิน'"  style="font-weight:bold; margin-top:20px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >จ่ายคืนจริง</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
             <CurrencyValue  class="w-full real-return" v-model="bailReturn"></CurrencyValue>
            </vs-col>
          </vs-row>

          <!--  เบอร์ติดต่อ  -->
          <vs-row v-if="returnPaymentMethod==='โอนเงิน'"  style="font-weight:bold; margin-top:20px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >เบอร์ติดต่อ</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="form-label px-2"
            >
              <vs-input class="w-full" name="transferContact" v-model="transferContact" />
            </vs-col>
          </vs-row>
        </div>
        <!-- </vs-table>END: RETURN DETAIL -->

        <!--------------------------------------------------------------------->
        <div style="border-top: 1px solid #E3E3E3; margin-bottom:10px;margin-top:20px; "></div>
        <!--------------------------------------------------------------------->

        <!--  ปุ่ม -->
        <!-- <vs-row vs-w="12" vs-type="flex" vs-justify="center">
          <vs-button
            @click="$router.back()"
            style="margin-right: 20px;"
            color="warning"
            type="filled"
          >กลับไปหน้าเช่า</vs-button>
          <vs-button color="success" type="filled">ยืนยันการเช่า</vs-button>
        </vs-row>-->
        <!--------------------------------------------------------------------->
        <vs-row vs-w="12" type="flex" vs-justify="flex-end">

          <vs-button @click="closePanel()" style="margin:10px;" color="danger" type="border">ยกเลิก</vs-button>

          <vs-button @click="update()" style="margin:10px; width:150px;" color="success" type="filled">ตกลง</vs-button>

        </vs-row>
      </vs-popup>
      <!-- END: ข้อมูลเพิ่มเติมการคืนชุด POP UP -->
    </template>

    <template>
      <!-- POP UP -->
      <vs-popup classContent="popup-example" title="บันทึกข้อความ" :active.sync="notePopupActive">
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->
        <!--  Add this to data part  (import vSelect from "vue-select")     "v-select": vSelect  -->
        <!--  :select >>> ...Selected /  :options >>> ...Options -->
        <vs-row class="row-form" vs-w="12" style="margin-top:20px;margin-bottom:30px;">
          <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="5"
            class="form-label px-2"
          >บันทึกข้อความ</vs-col> -->
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-10"
          >
            <vs-textarea v-model="remark"></vs-textarea>
          </vs-col>
        </vs-row>

        <vs-row vs-type="flex"
            vs-justify="flex-end"
            vs-align="center" class="row-form" vs-w="12" style="margin-top:30px;margin-bottom:30px;">


          <vs-button
            @click="closeNotePanel()"
            style="margin:10px;"
            color="danger"
            type="border"
          >ยกเลิก</vs-button>

          <vs-button @click="updatenote()" style="margin:10px;  width:150px;" color="success" type="filled">ตกลง</vs-button>
        </vs-row>

      </vs-popup>
      <!-- POP UP -->
    </template>


    <!-- RENTAL PAYMENT POP UP -->
    <template>
      <vs-popup classContent="popup-example" title="วิธีชำระค่าเช่า" :active.sync="rentalPaymentActive">
        <vs-row class="row-form" vs-w="12" style="margin-top:30px;margin-bottom:30px;">
        <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label pl-20 pb-5"
          >วิธีชำระค่าเช่า</vs-col> -->

          <!-- วิธีชำระค่าเช่า -->

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
          >
            <div
              :class="[rentalPaymentMethod === 'เงินสด' ? 'choose' : 'notChoose']"
              @click="changeRentalPayment('เงินสด')"
            >เงินสด</div>
            <div
              :class="[rentalPaymentMethod === 'โอนเงิน' ? 'choose' : 'notChoose']"
              @click="changeRentalPayment('โอนเงิน')"
            >โอนเงิน</div>
            <div
            :class="[
              rentalPaymentMethod === 'Alipay/wechat' ? 'choose' : 'notChoose'
            ]"
            @click="changeRentalPayment('Alipay/wechat')"
          >
             Alipay/wechat
          </div>
            <div
              :class="[rentalPaymentMethod === 'บัตรเครดิต' ? 'choose' : 'notChoose']"
              @click="changeRentalPayment('บัตรเครดิต')"
            >บัตรเครดิต</div>

            <div
              :class="[rentalPaymentMethod === 'ช่องทางอื่น' ? 'choose' : 'notChoose']"
              @click="changeRentalPayment('ช่องทางอื่น')"
            >ช่องทางอื่น</div>

          </vs-col>

          <!-- Calendar Rental Payment -->

          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-10 pt-8"
          >


          ระบุวันที่รับเงิน&nbsp;&nbsp;&nbsp;

            <div  clas="pl-8" style="display:inline-block; " >


                      <span clas="pl-8" style="font-size: 1.2rem; ">{{this.format_ddmmyyy(calendarRentalPayment)}} </span>

                      <i
                          style= " cursor:pointer; font-size:20px; margin-left:5px; margin-right:5px;vertical-align:middle;  border-radius:10px; position:absolute;"
                          class="material-icons iconhover">
                          date_range
                      </i>

                      <v-date-picker
                      class="pl-4 py-4 rentalPay"
                        style="width:50px; padding : 0 !important; border: 0; position:absolute; padding-left: -10px;"
                        ref="rentalCalendar"
                        :locale="{ id: 'th', firstDayOfWeek: 2, masks: {  L: 'DD/MM/YYYY' } }"
                        mode="date"
                        :key="rentalPaymentCode"
                        v-model="calendarRentalPayment"
                      />

                </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-10 "
          >
              <div style="font-size: 0.8rem; color:#EA5455;">*** หากไม่ระบุวันที่รับเงิน ระบบจะบันทึกวันที่รับเงินเป็นวันนี้ ({{this.format_ddmmyyy(new Date())}})</div>
        </vs-col>

        </vs-row>


      <vs-row vs-type="flex"
            vs-justify="flex-end"
            vs-align="center" class="row-form" vs-w="12" style="margin-top:30px;margin-bottom:30px;">

        <vs-button @click="closePanel()" style="margin:10px;" color="danger" type="border">ยกเลิก</vs-button>

        <vs-button @click="rentalPaymentUpdate()" style="margin:10px; width:150px;" color="success" type="filled">ตกลง</vs-button>

        </vs-row>
      </vs-popup>
      <!--  BAIL PAYMENT POP UP -->
    </template>


    <!-- RENTAL PAYMENT POP UP -->
    <template>
      <vs-popup classContent="popup-example" title="วิธีชำระค่าประกัน" :active.sync="bailPaymentActive">
        <vs-row class="row-form" vs-w="12" style="margin-top:30px;margin-bottom:30px;">
          <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="5"
            class="form-label px-2"
          >วิธีชำระค่าประกัน</vs-col> -->

          <!-- วิธีชำระค่าประกัน -->

          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
          >
            <div
              :class="[bailPaymentMethod === 'เงินสด' ? 'choose' : 'notChoose']"
              @click="changeBailPayment('เงินสด')"
            >เงินสด</div>
            <div
              :class="[bailPaymentMethod === 'โอนเงิน' ? 'choose' : 'notChoose']"
              @click="changeBailPayment('โอนเงิน')"
            >โอนเงิน</div>
            <div
            :class="[
              bailPaymentMethod === 'Alipay/wechat' ? 'choose' : 'notChoose'
            ]"
            @click="changeBailPayment('Alipay/wechat')"
          >
             Alipay/wechat
          </div>
            <div
              :class="[bailPaymentMethod === 'บัตรเครดิต' ? 'choose' : 'notChoose']"
              @click="changeBailPayment('บัตรเครดิต')"
            >บัตรเครดิต</div>

            <div
              :class="[bailPaymentMethod === 'ช่องทางอื่น' ? 'choose' : 'notChoose']"
              @click="changeBailPayment('ช่องทางอื่น')"
            >ช่องทางอื่น</div>


          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-10 pt-8"
          >

          ระบุวันที่รับเงิน&nbsp;&nbsp;&nbsp;


                    <div  clas="pl-8" style="display:inline-block; " >


                                <span clas="pl-8" style="font-size: 1.2rem; ">{{this.format_ddmmyyy(calendarBailPayment)}} </span>

                                <i
                                    style= " cursor:pointer; font-size:20px; margin-left:5px; margin-right:5px;vertical-align:middle;  border-radius:10px; position:absolute;"
                                    class="material-icons iconhover">
                                    date_range
                                </i>

                                <v-date-picker
                                class="pl-4 py-4 rentalPay"
                                  style="width:50px; padding : 0 !important; border: 0; position:absolute; padding-left: -10px;"
                                  ref="rentalCalendar"
                                  :locale="{ id: 'th', firstDayOfWeek: 2, masks: {  L: 'DD/MM/YYYY' } }"
                                  mode="date"
                                  :key="bailPaymentCode"
                                  v-model="calendarBailPayment"
                                />

                 </div>
          </vs-col>


          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-10 "
          >
              <div style="font-size: 0.8rem; color:#EA5455;">*** หากไม่ระบุวันที่รับเงิน ระบบจะบันทึกวันที่รับเงินเป็นวันนี้ ({{this.format_ddmmyyy(new Date())}})</div>
        </vs-col>


        </vs-row>
       <vs-row vs-type="flex"
            vs-justify="flex-end"
            vs-align="center" class="row-form" vs-w="12" style="margin-top:30px;margin-bottom:30px;">
          <vs-button @click="closePanel()" style="margin:10px;" color="danger" type="ิborder">ยกเลิก</vs-button>
          <vs-button @click="bailPaymentUpdate()" style="margin:10px; width:150px;" color="success" type="filled">ตกลง</vs-button>

         </vs-row>
      </vs-popup>
    </template>
    <!--  RENTAL PAYMENT POP UP -->


    <!-- BACK STATUS เปลี่ยนสถานะคืน -->
    <template>
      <!-- POP UP -->
      <vs-popup
        classContent="popup-example"
        title="เปลี่ยนสถานะคืน"
        :active.sync="backstatusPopupActive"
      >
        <vs-row class="row-form" vs-w="12" style="margin-top:20px;margin-bottom:30px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="10"
            vs-sm="10"
            vs-xs="10"
            class="form-label px-2"
          >
            เปลี่ยนสถานะ Order เลข
            <b>{{ this.orderNumber }}</b> เป็นรอรับชุด
          </vs-col>
        </vs-row>

        <vs-button @click="updateback()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
        <vs-button
          @click="closeBackStatusNotePanel()"
          style="margin:10px;"
          color="danger"
          type="filled"
        >ยกเลิก</vs-button>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- HEADER -->
    <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
        vs-sm="1"
        vs-xs="6"
        class="px-2"
      >วันที่คืนชุด</vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="3"
        vs-sm="3"
        vs-xs="6"
        class="px-2"
      >
        <div
          class="grid-layout-container alignment-block"
          style="vertical-align:text-top ;width:100%;"
        >
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
              class="px-2"
            >
              <v-date-picker
                style="width:100%;font-size:14px;"
                :locale="{
                  id: 'th',
                  firstDayOfWeek: 2,
                  masks: { L: 'DD/MM/YYYY' }
                }"
                mode="range"
                v-model="calendarData"
              />
              <!-- <datepicker
                style="width:100%;"
                placeholder="วันเริ่ม"
                format="yyyy-MM-dd"
                v-model="searchDate"
              ></datepicker>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >
              <datepicker
                style="width:100%;"
                placeholder="วันสิ้นสุด"
                format="yyyy-MM-dd"
                v-model="searchDateEnd"
              ></datepicker>-->
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="6"
        vs-sm="6"
        vs-xs="12"
        class="px-2"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
            class="px-2"
          >
            <vs-button @click="searchBtn()" style="width:100%;" color="primary" type="filled">ค้นหา</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
            class="px-2"
          >
            <vs-button @click="resetBtn()" style="width:100%;" color="warning" type="filled">reset</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
          >
            <!-- <vs-button
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0; "
              color="rgb(255, 114, 131)"
              type="filled"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                >print</i>
              </span>
              <span style=" vertical-align:middle;">พิมพ์ใบเสร็จ</span>
            </vs-button>-->
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>


    <!-- BRANCHES -->
       <!-- *** BEGIN : 2020-11-28 : Search By branches -->

    <!-- <vs-row vs-w="12">
      <ul v-if="permission === 'admin'" class="demo-alignment">
        <li
          @click="theSearch($event, index)"
          class="choose"
          v-for="(b, index) in allBranch"
          :key="index"
        >{{ b.branchName }}</li>
      </ul>


      <ul v-else class="demo-alignment">
        <li
          @click="theSearch($event, index)"
          class="choose"
          v-for="(b, index) in getMyBranches"
          :key="index"
        >{{ b.branchName }}</li>
      </ul>
    </vs-row> -->

    <!-- *** END : 2020-11-28 : Search By branches -->
    <!-- BRANCHES -->

    <!-- HEADER -->

    <!-- TABLE -->
    <vs-table style="z-index:1;" pagination max-items="20" search :data="orders">
      <template style="z-index:1 !important;" slot="thead">
        <vs-th sort-key="updateDateTime">ลงรายการ</vs-th>
        <vs-th sort-key="orderNumber">เลข Order</vs-th>
        <!-- <vs-th sort-key="productItem">ชื่อสินค้า</vs-th> -->
        <vs-th sort-key="customerName">ข้อมูลชื่อผู้เช่า</vs-th>
        <vs-th sort-key="rentalPriceTotal" style="width:12%;" >ยอดเช่าชุด</vs-th>
        <vs-th sort-key="bailTotal" style="width:12%;" >เงินประกัน</vs-th>
        <vs-th sort-key="pickupDate">วันรับชุด</vs-th>
        <vs-th sort-key="returnDate">วันคืนชุด</vs-th>
        <!-- <vs-th sort-key="orderStatus">สถานะ</vs-th> -->
        <!-- <vs-th>วิธีชำระ</vs-th> -->
        <vs-th>คืนสถานะ</vs-th>
        <vs-th>การจัดการ</vs-th>
      </template>

      <template style="z-index:1 !important;" slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td>
            <vs-checkbox v-model="data[indextr].selected" @click="addSelectedList(indextr, $event)"></vs-checkbox>
          </vs-td>-->
          <vs-td style="width:10%;">
            {{ formatDate(tr.updateDateTime) }}
            <br />
            {{ tr.updateBy }}
            <br />
            <!-- <div style="color:#9E0619; font-weight:light;">รายละเอียด</div> -->
            <br />
          </vs-td>
          <vs-td style="text-align:center;">
            <vs-button
              :href="getRedirectLink(tr.orderHeaderId)"
              target="blank"
              style="padding:0; font-size:11px; color: #2372CE; background-color: white !important; border: 0 white solid !important; font-weight:bold;"
            >{{ tr.orderNumber }}</vs-button>
            <br />
            {{ findBranchName(tr.branchId) }}
            <br />
            <div @click="changeToReturn($event, tr, indextr)" class="change-to-return">รอคืนชุด</div>
            <br />
          </vs-td>
          <!-- <vs-td>ข้อมูลสินค้า</vs-td> -->
          <vs-td style="max-width:150px; font-family: mitr;">
            {{ tr.customerName }}
            <br />
            {{ tr.customerAddress }}
            <br />
            {{ tr.customerPhone }}
          </vs-td>



          <!-- <vs-td style="padding-left:20px;">
            {{
            realRentalPrice(
            tr.rentalPriceTotal,
            tr.rentalDiscount,
            tr.promotionDiscount
            )
            }}
            <vx-tooltip text="ช่องทางชำระค่าเช่า" >
             <div   :class="showPaymentClass(tr.rentalPaymentMethod)"  @click="openRentalPaymentPanel($event,tr,indextr)"     class="bailPayment px-2 py-1" >{{ showPayment(tr.rentalPaymentMethod) }}</div>
            </vx-tooltip>
          </vs-td> -->
           <!-- ค่าเช่า ใช้ Net price -->
          <vs-td v-if="tr.netPrice > 0" style="padding-left:20px;">
            {{
            formatPrice(
            tr.netPrice
            )
            }}
            <vx-tooltip text="ช่องทางชำระค่าเช่า" >
             <div   :class="showPaymentClass(tr.rentalPaymentMethod)"   @click="openRentalPaymentPanel($event,tr,indextr)"  class="bailPayment px-2 py-1" >{{ showPayment(tr.rentalPaymentMethod) }}</div>
            </vx-tooltip>
          </vs-td>
          <vs-td v-else style="padding-left:20px;">
            {{
            realRentalPrice(
            tr.rentalPriceTotal,
            tr.rentalDiscount,
            tr.promotionDiscount
            )
            }}
            <vx-tooltip text="ช่องทางชำระค่าเช่า" >
             <div   :class="showPaymentClass(tr.rentalPaymentMethod)"   @click="openRentalPaymentPanel($event,tr,indextr)"  class="bailPayment px-2 py-1" >{{ showPayment(tr.rentalPaymentMethod) }}</div>
            </vx-tooltip>
          </vs-td>
          <vs-td style="padding-left:20px;">{{ realBail(tr.bailTotal, tr.bailDiscount) }}
            <vx-tooltip text="ช่องทางชำระเงินประกัน" >
              <div   :class="showPaymentClass(tr.bailPaymentMethod)"  @click="openBailPaymentPanel($event,tr,indextr)"   class="bailPayment px-2 py-1" >{{ showPayment(tr.bailPaymentMethod) }}</div>
            </vx-tooltip>
          </vs-td>


          <vs-td>{{ formatDate(tr.pickupDate) }}</vs-td>
          <vs-td>{{ formatDate(tr.returnDate) }}</vs-td>
          <!-- <vs-td>{{tr.orderStatus}}</vs-td> -->

          <vs-td>
            <i
              @click="backStatus($event, indextr, tr)"
              style="color:#0069ff; font-size:20px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >reply_all</i>
          </vs-td>
          <vs-td>
             <!-- *** 21Dec2021 * -->
            <i
              v-if="
                tr.remark1 === null ||
                  tr.remark1 === undefined ||
                  tr.remark1 === ''"
              @click="takeNote($event, tr)"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >speaker_notes</i>
             <!-- *** 21Dec2021 * -->
            <i
              v-else
              @click="takeNote($event, tr)"
              style="color:#0069ff; font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >speaker_notes</i>
            <br />
            <i
              @click="printRec($event, tr.orderHeaderId)"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >print</i>

          </vs-td>



          <div style="width: 100%; background-color:pink; height:50px; "></div>
        </vs-tr>
      </template>
    </vs-table>
    <!-- TABLE -->

    <br />
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import shapeFormat from "../../mixins/shapeFormat.js";
import CurrencyValue from "./Components/CurrencyValue";

export default {
  mixins: [shapeFormat],
  data() {
    return {
      calendarRentalPayment: null,
      calendarBailPayment: null,
      rentalPaymentCode : 0,
      bailPaymentCode : 0,


      calendarData: {},
      searchDateEnd: null,
      backstatusIndex: -1,
      backstatusPopupActive: false,
      original: [],
      searchlist: [],
      permission: "",
      allBranch: [],
      allDetail: [],
      deh: [],
      currentBranch: null,
      currentUser: "guest",
      roles: [],
      takenote: "",
      takenoteIndex: 0,
      theIndex: 0,
      //*** 21Dec2021 */
      currentObject: {},
      currentBackStatus: {},
      branchCheckbox: [],
      branches: ["ลาดพร้าว", "เมืองทองธานี"],
      branchSelect: [],

      // >>>>>>>> UPDATE INFO >>>>>>>>
      rentalPaymentActive: false,
      bailPaymentActive: false,
      returnPaymentMethod: "เงินสด",
      returnPaymentChannel: "ไม่ระบุ",


      rentalPaymentMethod: "ไม่ระบุ",
      rentalPaymentChannel: "ไม่ระบุ",

      bailPaymentMethod: "ไม่ระบุ",
      bailPaymentChannel: "ไม่ระบุ",
      bailReturn: 0,

      bringBackBy: "",
      state: " รอคืนชุด",
      orderNumber: "",

      // >>>>>>>> UPDATE INFO >>>>>>>>
      remark: "",
      deduction: 0,
      bankName: "",
      accountNumber: "",
      accountName: "",
      transferContact: "",


      bailDiscount: 0,
      bailTotal: 0,

      // >>>>>>>> UPDATE INFO >>>>>>>>

      statusToReturn: { label: "รับชุดแล้ว", value: "รับชุดแล้ว" },
      statusToReturnOption: [
        { label: "รับชุดแล้ว", value: "รับชุดแล้ว" },
        { label: "ยกเลิก", value: "ยกเลิก" }
      ],
      notePopupActive: false,
      popupActive: false,
      searchDate: null,
      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: { label: "เงินสด", value: "เงินสด" },
      payment_option: ["ไม่ระบุ", "เงินสด", "โอนเงิน" , "Alipay/wechat" , "บัตรเครดิต", "ช่องทางอื่น"],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: [],
      orders: []
    };
  },

  async created() {
    // this.orders = this.orders.filter(x => x.orderStatus === "รอรับชุด");
    // this.originalList = this.orders;

    this.$vs.loading();

    this.currentUser = JSON.parse(localStorage.getItem("username"));
    this.roles = JSON.parse(localStorage.getItem("roles"));

    // EDIT BRANCH
    this.thisBranch = this.$store.state.auth.currentBranch;

    // console.log("******** @ PICKUP %%%%%", this.$acl);
    // this.$store.dispatch("updateUserRole", {
    //     aclChangeRole: this.$acl.change,
    //     userRole: this.checkPermissionShare( this.roles )
    //   });

    this.permission = this.checkPermission(this.roles);

    // EDIT BRANCH
    this.allBranch = await this.loadBranch();

    // var branch = null;
    var ord = [];


    // if (this.myPermission === "admin") {
    //   ord = await this.loadAllOrderShare();

    //   for (var m = 0; m < this.allBranch.length; m++) {
    //     this.branchSelect[m] = true;
    //   }
    // } else {
    //   // EDIT BRANCH
    //   // branch = this.$store.state.auth.currentBranch;

    //   //  *** BEGIN : 2020-11-28 : Search By branches
    //   ord = await this.loadOrderByBranchShared(this.getMyBranches);
    //   //  *** END : 2020-11-28 : Search By branches

    //   // ord = await this.loadBranchOrderShare(branch.branchId);
    // }
    // console.log("ord >>> ", ord);

    //  *** BEGIN : 2020-12-04 : load just branch occupied
    ord = await this.loadOrderByBranchObjectShared(this.getCurrentBranch);
    //  *** END : 2020-12-04 : load just branch occupied


    // var con = { t1: "รอคืนชุด" };
    // var theorder = await this.post("/api/orderheader/get-by-status", con);
    //  *** END : 2020-11-28 : Search By branches

    // console.log("theorder >>> ", theorder);

    // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
    //  *** BEGIN : 2020-11-28 : Search By branches
    var theorder = await ord.filter(x => x.orderStatus === "รอคืนชุด");
    var sorted = await this.descendingDateSortShare(theorder, "updateDateTime");
    //  *** END : 2020-11-28 : Search By branches
    // console.log("theorder >>> ", theorder);

    this.orders = await sorted;
    this.original = await sorted;
    this.searchlist = await sorted;

    // console.log('this.orders?>>>> ',this.orders);


    // this.branchCheckbox = await this.allBranch;

    //  *** BEGIN : 2020-11-28 : Search By branches
     this.branchCheckbox =  this.getMyBranches;
    //  *** END : 2020-11-28 : Search By branches


    this.$vs.loading.close();


  },
  async mounted() {},
  computed: {
     //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches(){
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission(){
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    }
     //  *** BEGIN : 2020-11-28 : Search By branches
  },

  methods: {

    // *** BEGIN: RENTAL Payment update

    openRentalPaymentPanel(event, order, index) {
      event.stopPropagation();
      this.rentalPaymentMethod = order.rentalPaymentMethod;
      this.calendarRentalPayment = (order.rentalPaymentDate === null || order.rentalPaymentDate === null) ? this.formatDate(new Date()) :  order.rentalPaymentDate;
      this.calendarRentalPayment = this.formatDate(new Date());

      ++(this.rentalPaymentCode);

      this.orderHeaderId = order.orderHeaderId;
      this.state = order.orderStatus;
      this.theIndex = index;
      this.rentalPaymentActive = true;
    },
    closeRentalPaymentPanel() {
      this.rentalPaymentActive = false;
      this.resetValue();
    },
    changeRentalPayment(pay) {
      this.rentalPaymentMethod = pay;
    },
    changeReturnPayment(pay) {
      this.returnPaymentMethod = pay;
    },


    async rentalPaymentUpdate() {
      this.$vs.loading({ type: "radius" });
      // SAVE HEADER

      // console.log("when update rental >>>> ",this.formatDate(this.calendarRentalPayment))

      // update datetime in case it less than 2001-01-01T00:00:00
      if(this.formatDate(this.calendarRentalPayment) <=   this.formatDate("2001-01-01T00:00:00")){
        this.calendarRentalPayment = this.formatDate(new Date());
      }

      var ord = {
        rentalPaymentMethod: this.rentalPaymentMethod,
        rentalPaymentChannel: this.rentalPaymentChannel,
        rentalPaymentDateTime: this.formatDate(this.calendarRentalPayment)
        // updateBy: this.currentUser,
      };

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL +
            "/api/orderheader/rental-payment/" +
            this.orderHeaderId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          await this.reload();
          //  *** BEGIN : 2020-11-28 : Search By branches
          this.searchBtn();
          //  *** BEGIN : 2020-11-28 : Search By branches
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
          }, 500);
          this.popupActive = false;
          this.rentalPaymentActive = false;
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
        }, 700);
      }

  // *** END: RENTAL Payment update

      // >>>> START TO SAVE HEADER
    },

  // *** BEGIN: Bail Payment update

    openBailPaymentPanel(event, order, index) {
      event.stopPropagation();
      this.bailPaymentMethod = order.bailPaymentMethod;
      this.calendarBailPayment = (order.bailPaymentDate === null || order.bailPaymentDate === null) ? this.formatDate(new Date()) :  this.formatDate(order.bailPaymentDate);

      ++(this.bailPaymentCode);

      this.orderHeaderId = order.orderHeaderId;
      this.state = order.orderStatus;
      this.theIndex = index;
      this.bailPaymentActive = true;
    },
    closeBailPaymentPanel() {
      this.bailPaymentActive = false;
      this.resetValue();
    },
    changeBailPayment(pay) {
      this.bailPaymentMethod = pay;
    },
    async bailPaymentUpdate() {
      this.$vs.loading({ type: "radius" });
      // SAVE HEADER

      if(this.formatDate(this.calendarBailPayment) <=   this.formatDate("2001-01-01T00:00:00")){
        this.calendarBailPayment = this.formatDate(new Date());
      }

      var ord = {
        bailPaymentMethod: this.bailPaymentMethod,
        bailPaymentChannel: this.bailPaymentChannel,
        bailPaymentDateTime: this.formatDate(this.calendarBailPayment)

        // updateBy: this.currentUser,
      };

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL +
            "/api/orderheader/bail-payment/" +
            this.orderHeaderId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          await this.reload();
          //  *** BEGIN : 2020-11-28 : Search By branches
          this.searchBtn();
          //  *** BEGIN : 2020-11-28 : Search By branches
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
          }, 500);
          this.popupActive = false;
          this.bailPaymentActive = false;
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
        }, 700);
      }


      // >>>> START TO SAVE HEADER
    },

  // *** END: Bail Payment update




    printRec(event, orderid) {
      event.stopPropagation();
      window.open("/pages/rec2/" + orderid + "/f", "_blank");
    },
    showPayment(pay) {
      if (pay === "เงินสด" || pay === "โอนเงิน" ||  pay === "Alipay/wechat" ||  pay === "บัตรเครดิต" || pay === "ช่องทางอื่น") {
        return pay;
      } else {
        return "ยังไม่ชำระ";
      }
    },
    showPaymentClass(pay) {
      // console.log('pay >>> ',pay);

      switch (pay) {

        case "เงินสด":
          return "label-btn-blue";

        case "โอนเงิน":
          return "label-btn-green";

        case "Alipay/wechat":
           return "label-btn-purple";
        case "บัตรเครดิต":
          return "label-btn-yellow";
        case "ช่องทางอื่น":
          return "label-btn-purple";
        default:
          return "label-btn-black";
      }
    },

    theLastBail() {
      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);

      var total = isNaN(Number(this.bailTotal)) ? 0 : Number(this.bailTotal);
      return total - bd;
    },
    getRedirectLink(orderId) {
      return "/backoffice/order-detail/" + orderId;
    },
    async detailOneOrder(orderId) {
      var list = await this.allDetail.filter(x => x.orderHeaderId === orderId);
      // console.log("orderId >>> ", orderId, " list >>> ", list);
      return list;
    },
    theSearch(event, index) {
      this.activeb(event, index);
      this.searchBtn();
    },
    activeb(event, index) {
      // var blist = this.allBranch;
      //  *** BEGIN : 2020-11-28 : Search By branches
      var blist = this.getMyBranches;
      //  ***  END : 2020-11-28 : Search By branches
      this.branchCheckbox = [];

      if (event.target.className == "choose") {
        this.branchSelect[index] = false;
        event.target.className = "notChoose";
      } else {
        event.target.className = "choose";
        this.branchSelect[index] = true;
      }

      // ถ้า branchSelect ใส่ค่า
      for (var p = 0; p < blist.length; p++) {
        if (this.branchSelect[p] === true) {
          this.branchCheckbox.push(blist[p]);
        }
      }
    },
    searchBtn() {

            //  *** BEGIN : 2020-11-28 : Search By branches
      if(this.calendarData === null )
      {
        this.calendarData = {};
      }
     if(Object.entries(this.calendarData).length === 0 ){
       this.searchDate = null;
       this.searchDateEnd = null;
     }
      //  *** BEGIN : 2020-11-28 : Search By branches


      if (this.searchDate !== null && this.searchDateEnd !== null) {
        var searchDate = this.formatDate(this.searchDate);
        var searchDateEnd = this.formatDate(this.searchDateEnd);
        if (searchDate && searchDateEnd) {
          this.orders = this.searchByDate(
            this.orders,
            searchDate,
            searchDateEnd,
            "returnDate"
          );
        }
        // console.log("AFTER searchByDate >>> ", this.orders);
      }


    //   var list = [];
    //   var list2 = [];
    //   this.orders = [];
    //   var searchdate = this.formatDate(this.searchDate);
    //   var searchdateEnd = this.formatDate(this.searchDateEnd);
    //   // console.log("searchdateEnd >>> ", searchdateEnd);

    //   var startNull = false;
    //   var endNull = false;

    //   if (
    //     this.searchDate === "" ||
    //     this.searchDate === null ||
    //     this.searchDate === undefined
    //   ) {
    //     startNull = true;
    //     // console.log("**** >>> startNull ");
    //   }

    //   if (
    //     this.searchDateEnd === "" ||
    //     this.searchDateEnd === null ||
    //     this.searchDateEnd === undefined
    //   ) {
    //     endNull = true;
    //     // console.log("**** >>> endNull ");
    //   }




    //   // console.log(
    //   //   "startNull >>> ",
    //   //   startNull,
    //   //   " endNull >>> ",
    //   //   endNull,
    //   //   " startNull&&endNull >>> ",
    //   //   startNull && endNull
    //   // );
    //   // console.log(
    //   //   "this.searchDate >>> ",
    //   //   this.searchDate,
    //   //   " this.searchDateEnd >>> ",
    //   //   this.searchDateEnd
    //   // );



    //   var searchList = [];

    //   if (startNull && endNull) {
    //     searchList = this.original;
    //     // console.log("BOTH NULL");
    //   } else {
    //     // console.log("Either one NULL");
    //     list = this.original;
    //     var tmplist = [];
    //     if (!startNull) {
    //       tmplist = list.filter(
    //         s => this.formatDate(s.returnDate) >= searchdate
    //       );
    //       // console.log("startNull NOT NULL");
    //     } else {
    //       tmplist = list;
    //     }

    //     // console.log("AFTER SEARCH DATE >>>> ", tmplist);

    //     list2 = tmplist;
    //     var tmplist2 = [];
    //     // console.log("******* END NULL : ", endNull);
    //     if (!endNull) {
    //       tmplist2 = list2.filter(
    //         e => this.formatDate(e.returnDate) <= searchdateEnd
    //       );
    //       // console.log("endNull NOT NULL");
    //     } else {
    //       tmplist2 = list2;
    //     }

    //     // console.log("AFTER SEARCH DATE END >>>> ", tmplist2);

    //     searchList = tmplist2;
    //   }

    //   // console.log(
    //   //   "@ CHECK POINT  sthis.branchCheckbox.length  >>> ",
    //   //   this.branchCheckbox.length
    //   // );

    //   if (this.branchCheckbox.length >= 1) {
    //     for (var k = 0; k < searchList.length; k++) {
    //       for (var j = 0; j < this.branchCheckbox.length; j++) {
    //         if (searchList[k].branchId === this.branchCheckbox[j].branchId) {
    //           this.orders.push(searchList[k]);
    //         }
    //       }
    //     }
    //   }
    },
    resetBtn() {
      this.searchDate = "";
      this.searchDateEnd = "";
      this.orders = this.original;
      this.calendarData = {};
    },
    async reload() {
      // var branch = null;
      var ord = [];
      this.permission = this.checkPermission(this.roles);


      //  *** BEGIN : 2020-12-04 : load just branch occupied
      ord = await this.loadOrderByBranchObjectShared(this.getCurrentBranch);
      //  *** END : 2020-12-04 : load just branch occupied

      // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
      var theorder = await ord.filter(x => x.orderStatus === "รอคืนชุด");
      var sorted = await this.descendingDateSortShare(theorder, "updateDateTime");

      this.orders = await sorted;
      this.searchlist = await sorted;
      this.original = await sorted;
      // console.log('this.orders >>> ',this.orders);


      // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
    },
    changeToReturn: function(event, order, index) {
      event.stopPropagation();
      this.bailPaymentMethod = order.bailPaymentMethod;
      this.orderHeaderId = order.orderHeaderId;

      this.deduction = order.deduction;
      this.remark = order.remark1;
      this.bankName = order.bankName;
      this.accountNumber = order.accountNumber;
      this.accountName = order.accountName;
      this.transferContact = order.transferContact;
      this.bailTotal = order.bailTotal;
      this.bailDiscount = order.bailDiscount;

      this.theIndex = index;
      this.popupActive = true;
    },
    async update() {
      // >>>>>>>>>> Validate INFO
      if (
        (this.bankName === null ||
        this.bankName === undefined ||
        this.bankName === ""
        ) && this.returnPaymentMethod === "โอนเงิน"
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ใส่ชื่อธนาคาร");
        return false;
      }

      // >>>>>>>>>> Validate INFO
      if (
        (this.accountNumber === null ||
        this.accountNumber === undefined ||
        this.accountNumber === "") && this.returnPaymentMethod === "โอนเงิน"
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ใส่เลขบัญชี");
        return false;
      }

      // >>>>>>>>>> Validate INFO
      if (
       ( this.accountName === null ||
        this.accountName === undefined ||
        this.accountName === "" )  && this.returnPaymentMethod === "โอนเงิน"
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ใส่ชื่อธนาคาร");
        return false;
      }

      if(this.bailReturn === null || this.bailReturn === undefined || this.bailReturn === ""){
        this.bailReturn = 0;
      }

      // >>>>>>>>>> เช็คจำนวนเงินจ่ายคืนจริง
      if (
           (
             (this.bailReturn > ( this.bailTotal - this.bailDiscount - this.deduction ))
            || (this.bailReturn < 0)
           )

           && this.returnPaymentMethod === "เงินสด"
      ) {
        // this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ใส่ชื่อธนาคาร");
        this.$swal({
          icon: 'warning',
          title: 'จำนวนเงินที่คืนมากกว่าวงเงินประกัน',
        });
        return false;
      }




      // >>>>>>>>>> Validate INFO
      if (
        (
        this.transferContact === null ||
        this.transferContact === undefined ||
        this.transferContact === ""
        ) &&  this.returnPaymentMethod === "โอนเงิน"
      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบ",
          "ยังไม่ใส่เบอร์ติดต่อกลับหลังโอนเงิน"
        );
        return false;
      }

      this.$vs.loading({ type: "radius" });
      // SAVE HEADER

      var ord = {
        deduction: this.deduction,
        remark1: this.remark,
        bankName: this.bankName,
        accountNumber: this.accountNumber,
        accountName: this.accountName,
        transferContact: this.transferContact,

        returnMethod: this.returnPaymentMethod,
        returnChannel: this.returnPaymentChannel,
        bailReturningAmount:  this.bailReturn,
        bringBackBy: this.currentUser,

        updateBy: this.currentUser,

        orderStatus: "คืนชุดแล้ว"
      };

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL +
            "/api/orderheader/bringback/" +
            this.orderHeaderId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
        //  *** BEGIN : 2020-11-28 : Search By branches
        await this.reload();
        this.searchBtn();
        //  *** END : 2020-11-28 : Search By branches
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");

          }, 500);
          this.popupActive = false;
          this.resetValue();
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
          this.resetValue();
        }, 500);
      }

      // >>>> START TO SAVE HEADER
    },
    resetValue() {
      this.bailPaymentMethod = "ไม่ระบุ";
      this.returnPaymentMethod = "เงินสด";
      this.state = "ไม่ระบุ";
      this.theIndex = -1;
      this.takenoteIndex = -1;
      this.remark = "";

      this.deduction = "";

      this.bankName = "";
      this.accountNumber = "";
      this.accountName = "";
      this.transferContact = "";
      this.calendarData = {};
    },
    closePanel() {
      this.popupActive = false;
      this.rentalPaymentActive = false;
      this.bailPaymentActive = false;
      this.resetValue();
    },
    changeStatus(state) {
      this.state = state;
    },
    changePayment(pay) {
      this.bailPaymentMethod = pay;
    },
    // showPayment(pay) {
    //   if (pay === "เงินสด" || pay === "โอนเงิน" || pay === "บัตรเครดิต") {
    //     return pay;
    //   } else {
    //     return "ไม่ระบุ";
    //   }
    // },

    async findCurrentBranch(roles) {
      // >>>>>>> หา branch จาก  role ของ user >>>>>>>>>
      let branch_th = "1";
      var userrole = [];
      userrole = roles.find(x => x.roleName.includes("staff-"));
      if (userrole.length === 0 || userrole === undefined) {
        branch_th = "1";
      } else {
        var role_split = userrole.roleName.split("-");
        if (role_split[1] === null || role_split[1] === undefined) {
          branch_th = "1";
        }
        branch_th = role_split[1];
      }

      // console.log(" branch_th >>>> ", branch_th);

      var allBranches = await this.allBranch;

      var thisBranch = await allBranches.find(a => a.code === branch_th);

      return thisBranch;
    },

    findBranchName(id) {
      var b = this.allBranch.find(i => i.branchId === id);
      // console.log("@ BRANCH NAME >>> ", b, " ID >>> ", id);

      // return b.branchName;
      if (b === null || b === undefined) return "ไม่ระบุ";
      else return b.branchName;
    },

    async loadBranch() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch",
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
        // console.log("error", error);
      }

      return await response.data;
    },
    checkPermission(roles) {
      var pm = roles.filter(
        x =>
          x.roleName === "owner" ||
          x.roleName === "admin" ||
          x.roleName === "accountingadmin" ||
          x.roleName === "useradmin"
      );
      if (pm.length >= 1) {
        return "admin";
      } else {
        return "staff";
      }
    },
    confirmToChangeStatus(event) {
      event.stopPropagation();
      // this.orders[this.theIndex].orderStatus = "รับชุดแล้ว";
      // this.orders = this.orders.filter(x => x.orderStatus === "รอรับชุด");

      this.popupActive = false;
    },
    backStatus(event, index, item) {
      event.stopPropagation();
      this.currentBackStatus =  {};
      this.currentBackStatus =  item;
      this.orderNumber = item.orderNumber;
      this.backstatusPopupActive = true;
      this.backstatusIndex = index;
    },
    closeBackStatusNotePanel() {
      this.backstatusPopupActive = false;
      this.resetValue();
      this.orderNumber = "";
      this.currentBackStatus =  {};
    },
    async updateback() {
      this.$vs.loading({ type: "radius" });
      var ordId = this.currentBackStatus.orderHeaderId;
      // console.log(
      //   "ordId >>> ",
      //   ordId,
      //   " this.takenoteIndex ",
      //   this.takenoteIndex
      // );

      var response;
      try {
        response = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/backstatus/" + ordId,
          {
            note: this.remark,
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );

        if (response.status === 200) {
          //  *** BEGIN : 2020-11-28 : Search By branches
          await this.reload();
          this.searchBtn();
          //  *** END : 2020-11-28 : Search By branches

          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
            this.popupActive = false;
            this.closeBackStatusNotePanel();
          }, 300);
        }
      } catch (err) {
        this.noticeError(err);
      }
    },
    //*** 21Dec2021 */
    takeNote(event, tr) {
       event.stopPropagation();
      // console.log("@@@@@ TAKE NOTE >>> ", this.orders[index]);
      //*** 21Dec2021 */
      this.currentObject = {};
      this.currentObject = Object.assign({},tr);
      this.remark = tr.remark1;
      this.notePopupActive = true;
    },
    //*** 21Dec2021 */
    async updatenote() {
      this.$vs.loading({ type: "radius" });
     var ordId = this.currentObject.orderHeaderId;

      var response;
      try {
        response = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/takenote1/" + ordId,
          {
            note: this.remark,
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );

        if (response.status === 200) {
          //  *** BEGIN : 2020-11-28 : Search By branches
          await this.reload();
          this.searchBtn();
          //  *** END : 2020-11-28 : Search By branches
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
            this.popupActive = false;
            this.closeNotePanel();
          }, 300);
        }
      } catch (err) {
        this.noticeError(err);
      }
    },
    closeNotePanel() {
      this.notePopupActive = false;
      this.$vs.loading.close();
      this.resetValue();
    },

    selectAllOrders: function(event) {
      event.stopPropagation();

      var ind = 0;

      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0 || this.selectStatus === 2) {
        // console.log("expected >>> 0 or 2");
        this.selectedList = [];
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = true;
          this.selectedList.push(this.orders[ind]);
        }
        this.selectStatus = 1;
      } else if (this.selectStatus === 1) {
        // console.log("expected >>> 1");
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = false;
        }
        this.selectedList = [];
        this.selectStatus = 0;
      }
      this.fromSelectAll = true;
      // console.log(
      //   "SelectAll ==> " + this.selectAll + " STATUS  ===> " + this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    addSelectedList(indextt, event) {
      event.stopPropagation();
      // console.log(this.orders[indextt].selected);

      if (!this.orders[indextt].selected) {
        this.orders[indextt].selected = true;
        this.selectedList.push(this.orders[indextt]);
        // console.log("ADDED");
      } else {
        if (this.selectedList) {
          this.orders[indextt].selected = false;
          var foundIndextt = this.selectedList.findIndex(
            x => x.orderNumber === this.orders[indextt].orderNumber
          );
          this.selectedList.splice(foundIndextt, 1);
          // console.log("REMOVED");
        }
      }
      this.orders[indextt].selected = !this.orders[indextt].selected;
      // console.log(
      //   "SelectAll ==> " +
      //     this.selectAll +
      //     "TRUE STATUS  ===> " +
      //     this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    }
  },
  // created: function() {
  //   this.orders = this.orders.filter(x => x.orderStatus === "รอรับชุด");
  //   this.originalList = this.orders;
  // },
  components: {
    Prism,
    Datepicker,
    "v-select": vSelect,
    CurrencyValue
  },
  watch: {

    calendarRentalPayment(){
      ++(this.rentalPaymentCode);
    },

    calendarBailPayment(){
      ++(this.bailPaymentCode);
    },



    deduction(){
      this.bailReturn = this.bailTotal - this.bailDiscount - this.deduction ;
    },
    bailTotal(){
      this.bailReturn = this.bailTotal - this.bailDiscount - this.deduction ;
    }
    ,
    async getCurrentBranch(){
      await this.reload();
      this.searchBtn();
      // console.log('curent brach changed !!! >>> ',value.branchName);

    },

    calendarData: {
      handler(value) {
        this.searchDate = this.formatDate(value.start);
        this.searchDateEnd = this.formatDate(value.end);
      },
      deep: true
    },
    orders: {
      handler() {},
      deep: true
    },

    selectedList: function() {
      var ordersLength = this.orders.length;
      var selectedLength = this.selectedList.length;

      if (ordersLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN ordersLength == undefined");

        // console.log("SelectAll");
      }
      if (selectedLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN selectedLength == undefined)");
      }

      if (ordersLength == selectedLength && selectedLength > 0) {
        this.selectStatus = 1;
        if (this.fromSelectAll === false) this.selectAll = true;
        // console.log("IN ordersLength == selectedLength && selectedLength > 0");
        // console.log("SelectAll");
      } else {
        if (selectedLength == 0 || selectedLength == undefined) {
          this.selectStatus = 0;
          if (this.fromSelectAll === false) this.selectAll = false;
          // console.log("IN selectedLength == 0 || selectedLength == undefined");
        } else {
          this.selectStatus = 2;
          if (this.fromSelectAll === false) this.selectAll = true;
          // console.log("IN ELSE");
          // console.log("Select some");
        }
      }
      // console.log(this.orders);
      this.fromSelectAll = false;
      // console.log(
      //   "WACTCH selectedList >>> this.selectAll : " +
      //     this.selectAll +
      //     "  >>> this.selectStatus :  " +
      //     this.selectStatus
      // );

      // console.log(this.orders);
      // console.log(this.selectedList);
    }
    // branchCheckbox: function() {
    //   this.searchBtn();
    // }
  }
};
</script>

<style>
.rentalPay input{
  border: none;
  background-color: transparent;
  color: transparent;
}
.bailPay input{
  border: none;
  background-color: transparent;
  color: transparent;
}
.vs-textarea{
  height: 150px;
}

.vs__selected-options .vs__search{
  display: block !important;
}

/*
.rentalPayment{
 color: #cbcbcb; background-color: rgb(249 249 249); width:fit-content;
}

.bailPayment{
 color: #cbcbcb; background-color: rgb(249 249 249); width:fit-content;
}


.label-btn-blue{
 color: #0069ff !important; background-color:#e5efff!important; width:fit-content;
}

.label-btn-green{
 color :  #7ED321 !important; background-color: #7ed3212b  !important; width:fit-content;
}

.label-btn-yellow
{
  color: #f5a623 !important; background-color:#ffedd0  !important; width:fit-content;
} */

.real-return .vs-inputx{
  background-color:#fce6e5 ;
}

.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.changetoreturn {
  background: #f5a623;
  border: 1px solid #ad6c02;
  border-radius: 16px;
  font-family: 'mitr';
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}

/* v-select  */
.clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none !important;
}
.dropdown-menu {
  margin-top: 5 !important;
  background-color: white;
}
.selected-tag {
  width: 100% !important;
}
.vs__actions {
  display: none !important;
}

.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: 'mitr';
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.con-vs-popup .vs-popup {
  width: 750px !important;
}

/* v-select  */
</style>
